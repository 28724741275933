import React from "react";
import Router from "next/router";
import { connect } from "react-redux";

import { getLocale } from "../helpers/stateFunctions";
import SBheader from "../components/SBheader";
import SBnewFooter from "../components/SBnewFooter";

import { TEXTS } from "../helpers/statics";

class Error extends React.Component {
  static getInitialProps(context) {
    if (decodeURI(context?.req?.url) === "/coffee/dark/caffè-verona-/whole-bean") {
      context.res.writeHead(308, {
        Location: "/menu/evde-kahve-keyfi-1033/cekirdek-kahveler-1034",
      });
      context.res.end();
    }

    const statusCode = context.res ? context.res.statusCode : context.err ? context.err.statusCode : null;
    return {
      statusCode,
    };
  }

  backToHomepage() {
    Router.push("/main", "/");
  }

  render() {
    let _state = this.props;

    return (
      <div>
        <SBheader />

        <main
          id="content"
          className="homeCapsul height-100 flex flex-column"
          role="main"
        >
          <div className="sb-headerCrate flex flex-column bg-white  flex-shrink-none">
            <div className="flex flex-grow flex-column ">
              <div className="container--headerCrate sb-headerCrate--content size12of12 ">
                <p
                  onClick={this.backToHomepage}
                  style={{ marginBottom: "-16px", cursor: "pointer" }}
                >
                  {"<"} {TEXTS.error_back[getLocale(_state)]}
                </p>
                <h1
                  className="sb-heading sb-heading--large text-bold"
                  tabIndex={-1}
                  style={{ lineHeight: "36px" }}
                  data-e2e="welcome-message"
                >
                  {TEXTS.error[getLocale(_state)]}
                </h1>
              </div>
            </div>
          </div>

          <div className="sb-contentCrate flex-grow">
            <div className="sb-content-capsul relative flex-auto">
              <div className="flex-auto">
                <div>
                  <div
                    className="container my8 text-center flex-grow"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      minHeight: "70vh",
                    }}
                  >
                    <img
                      src="https://content.sbuxtr.com/images/404-icon.png"
                      width={150}
                      height={150}
                      alt="not-found"
                      style={{ marginRight: "7%" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "2.7rem",
                          lineHeight: "3.7rem",
                          fontWeight: "700",
                          textAlign: "left",
                          marginBottom: "1.6rem",
                        }}
                      >
                        {TEXTS.error_title[getLocale(_state)]}
                      </h2>
                      <p
                        style={{
                          fontSize: "2rem",
                          lineHeight: "2.7rem",
                          letterSpacing: "-.02rem",
                          textAlign: "left",
                          margin: "0px",
                        }}
                      >
                        {TEXTS.error_subtitle[getLocale(_state)]}
                      </p>
                    </div>
                  </div>
                  <hr
                    aria-hidden="true"
                    className="sb-rule sb-rule--thin mb4 lg-mb6"
                  />

                  <SBnewFooter />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, mapDispatchToProps)(Error);
